<template>
	<div v-if="showViewId==0">
		<div class="table-box">
			<!-- 头部功能区 -->
			<div class="header-box">
				<div class="header-left"><span style="min-width: 100px;">编码标准列表</span>
				</div>
				<div class="header-right">
				    <el-input v-model="searchValue" placeholder="搜索"></el-input>
				    <div class="hdBtnBox"  style="display: flex; margin-left: 10px;">
				    	<el-button type="primary" icon="el-icon-search">搜索</el-button>
				    	 <el-button type="primary" icon="el-icon-delete">添加</el-button>
				    </div>
			    </div>
			</div>
			<!-- 表格区域 -->
			<div class="table-container">
				<div v-for="item in standardList" class="item-box" :key="item.mappingtable" @click="view(item)">
					<div class="item-right">
						<p style="text-align: left;"><span class="iconfont icon-a-form1"></span><span>表名：</span>{{item.tablename}}</p>
						<!-- <p></p> -->
						<p><span>创建人：{{item.creatusernickname}}</span><span>创建时间：{{item.creattimestr}}</span></p>

						<p v-show="item.updateusernickname"><span>修改人：{{item.updateusernickname}}</span><span>修改时间：{{item.updatetimestr}}</span></p>
						<p style="text-align: center;" v-show="!item.updateusernickname">暂无修改记录</p>
						<div class="btn-box">
							<i class="el-icon-edit" @click.stop="updateShow(item)" v-if="action.updatetablename"></i>
							<i class="el-icon-delete" @click.stop="delet(item)" v-if="action.deletetable"></i>
						</div>
					</div>
				</div>
			</div>
		</div>
		<el-dialog title="修改表单" :visible.sync="dialogVisible" :close-on-click-modal="false" width="30%">
			<el-form :model="formObj" ref="tableupdateform" label-width="100px" class="demo-ruleForm">
				<el-form-item label=" 表名" prop="tablename" :rules="[
		        { required: true, message: '表名不能为空'}
		      ]">
					<el-input v-model="formObj.tablename"></el-input>
				</el-form-item>
			</el-form>
			<div style="text-align: right;">
				<el-button type="danger" @click="dialogVisible=false">取消</el-button>
				<el-button type="primary" @click="tableupdate">确认</el-button>
			</div>
		</el-dialog>
	</div>
	<div v-else>
		<table-item :tableGuid="showViewId" :tablename="tablename"></table-item>
	</div>
</template>
<script>
import {
  mapState
} from 'vuex'

import CodingStandard from './CodingStandard'
export default {
  data () {
    return {
      searchValue: '',
      showViewId: 0,
      standardList: [],
      tablename: '',
      labelTable: '',
      isUpdateID: 0,
      focusState: false,
      dialogVisible: false,
      formObj: {},
      oldName: ''

    }
  },
  components: {
    CodingStandard
  },
  directives: {
    focus: {
      update: function (el, {
        value
      }) {
        if (value) {
          el.children[0].focus()
        }
      }
    }
  },
  mounted () {
    this.init()
    console.log(this.action)
  },
  computed: {
    ...mapState('action', {
      action: 'action'
    })
  },
  methods: {

    init () {
      this.$axios.get('/api/formhandle/getalltable').then(res => {
        this.standardList = res
        console.log(res)
      })
    },
    // 修改表名
    updateShow (item) {
      console.log(item)
      var _this = this
      this.formObj = JSON.parse(JSON.stringify(item))
      this.oldName = item.tablename
      this.dialogVisible = true
      this.labelTable = item.tablename
      this.isUpdateID = item.mappingtable
      setTimeout(() => {
        _this.focusState = true
      }, 1)
    },
    tableupdate () {
      this.$refs.tableupdateform.validate(valid => {
        if (valid) {
          if (this.oldName == this.formObj.tablename) {
            this.$message({
              showClose: true,
              message: '表名未修改',
              type: 'warning'
            })
          } else {
            this.$axios.get(
              `/api/formhandle/updatetable?mappingTable=${this.formObj.mappingtable}&newTableName=${this.formObj.tablename}`
            ).then(res => {
              console.log(res)
              if (res == '修改成功') {
                this.$message({
                  showClose: true,
                  message: '修改成功',
                  type: 'success'
                })
              }
              // 前端更新数据
              let index = this.standardList.findIndex(item => {
                return item.mappingtable == this.formObj.mappingtable
              })
              this.$set(this.standardList, index, this.formObj)
              this.dialogVisible = false
            })
          }
        } else {
          return false
        }
      })
    },
    update (item) {
      this.$axios.get('/api/formhandle/updatetable?mappingTable=' + item.mappingtable + '&newTableName=' + this.labelTable)
        .then(res => {
          item.tablename = this.labelTable
          this.labelTable = ''
          this.isUpdateID = 0
          this.focusState = false
        })
    },
    onInputBlur () {
      this.labelTable = ''
      this.isUpdateID = 0
      this.focusState = false
    },
    delet (item) {
      this.$confirm('此操作将删除该标准, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var arr = []
        arr.push(item.mappingtable)
        this.$axios.post('/api/formhandle/deletetable', arr).then(res => {
          console.log(res)
          if (res == '删除成功') {
            this.$message({
              showClose: true,
              message: '删除成功',
              type: 'success'
            })
            this.standardList = this.standardList.filter(item => {
              return item.mappingtable != arr[0]
            })
          }
        })
      })
    },
    view (item) {
      this.showViewId = item.mappingtable
      this.tablename = item.tablename
    },
    // 还原，子界面返回此界面
    reback () {
      this.showViewId = 0
      this.init()
    }
  }
}
</script>
<style>
	.el-input--mini .el-input__inner {
		height: 23px;
		line-height: 23px;
		padding: 0;
	}

	.el-button--mini,
	.el-button--mini.is-round {
		padding: 1px 8px;
	}

	/* //修改table边框颜色 */
	.el-table--border:after,
	.el-table--group:after,
	.el-table:before {
		background-color: #e3e3e3;
	}

	.el-table--border,
	.el-table--group {
		border-color: #e3e3e3;
	}

	.el-table td,
	.el-table th.is-leaf {
		border-bottom: 1px solid #e3e3e3;
	}

	.el-table--border th,
	.el-table--border th.gutter:last-of-type {
		border-bottom: 1px solid #e3e3e3;
	}

	.el-table--border td,
	.el-table--border th {
		border-right: 1px solid #e3e3e3;
	}

	.el-icon-arrow-down:before {
		content: "\e790";
	}

	/* 对所有小图标进行了更改 */
	.el-button--mini.is-circle {
		margin-left: 5px;
		font-size: 16px;
		padding: 2px;
		border-color: #78c7e6;
		color: #159dd2;
		background-color: #ebf6fb;
		font-weight: bold;
	}

	.header-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		/* background-color: #aaffff; */
	}

	.margin {
		margin-left: 5px;
		margin-right: 5px;
		cursor: pointer;
	}

	.closeBtn {
		display: none;
		cursor: pointer;
		font-size: 16px;
		color: #ac0000;
		/* transition: .5s; */
	}

	.header-container:hover .closeBtn {
		/* background-color: #000000; */
		display: inline;
		font-size: 16px;
		color: #ac0000;
	}

	.header-box {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 69px;
		/* line-height: 70px; */
		box-shadow: 0px 2px 15px 0px rgba(187, 187, 187, 0.5);
		border-radius: 8px;
		margin-bottom: 10px;
		background-color: #FFFFFF;
	}

	.header-left {
		display: flex;
		align-items: center;
		font-weight: bold;
		margin-left: 30px;
	}

	.header-right {
		display: flex;
		height: 40px;
		margin-right: 30px;
	}

	.table-box {
		padding: 15px 25px;
		height: calc(100vh - 100px);
		box-sizing: border-box;
		background-color: #e7e7e7;
	}

	.table-container {
		display: flex;
		height: calc(100vh - 250px);
		flex-wrap: wrap;
		overflow-y: auto;
		align-content: flex-start;
		box-shadow: 0px 2px 15px 0px rgba(187, 187, 187, 0.5);
		border-radius: 8px;
		background-color: #FFFFFF;
		padding: 20px;
	}

	.item-box {
		/* display: flex; */
		/* align-items: auto; */
		/* background-color: #000000; */
		position: relative;
		overflow: hidden;
		cursor: pointer;
		width: 23%;
		min-width: 380px;
		height: 139px;
		box-shadow: 0px 2px 15px 0px rgba(187, 187, 187, 0.5);
		border-radius: 8px;
		margin: 10px;
		box-sizing: border-box;
		padding: 20px 30px;
	}

	.item-left {
		font-size: 36px;
	}

	.item-right {
		height: 100px;
		/* background-color: #000003; */
	}

	.item-right p:nth-child(1) {
		font-size: 14px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #5F5F5F;
		margin-bottom: 26px;
	}

	.item-right p span:nth-child(1) {
		margin-right: 28px;
	}

	.item-right p:nth-child(2) {
		margin-bottom: 10px;
	}

	.item-right p:nth-child(1) span:nth-child(1) {
		margin-right: 15px;
	}

	.item-right p {
		text-align: left;
		font-size: 16px;
		/* margin: 0; */
		line-height: 20px;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #5F5F5F;
	}

	.btn-box {
		display: none;
		position: absolute;
		top: 20px;
		right: 28px;
		color: #409eff;
	}

	.btn-box i:nth-child(1) {
		margin-right: 20px;
	}

	.item-box:hover .btn-box {
		display: block;
	}

	/* .el-dropdown{
		display: block;
		width: 100%;
	}  */
	.hdBtnBox .el-button--primary:nth-child(1) {
		background: #475065;
		border: none;
	}

	.hdBtnBox .el-button--primary:nth-child(2) {
		background: #0094CE;
		border: none;
	}

	.hdBtnBox .el-button--primary:nth-child(3) {
		background: #0094CE;
		border: none;
	}
</style>
