<template>
	<div class="table-box">
		<!-- 头部功能区 -->
		<div class="header-box">
			<!-- 原来的表单管理 -->
			<!-- <div class="header-left"><span style="min-width: 100px;">表单管理</span>
			 <el-select v-model="selectValue" filterable placeholder="请选择" @change="valueChange">
			    <el-option
			      v-for="item in tableNameList"
			      :key="item"
			      :label="item"
			      :value="item">
			    </el-option>
			  </el-select>
			</div> -->
			<!-- 标准管理下拉选择区域 -->
			<!-- <span>{{tablename}}</span> -->
			<div class="header-left"><span style="min-width: 100px;" v-if="action.updatetable">选择标准</span>
			 <el-select v-model="selectValue" filterable placeholder="请选择" @change="valueChange" v-if="action.updatetable">
			    <el-option
			      v-for="item in standardList"
			      :key="item.id"
			      :label="item.name"
			      :value="item.name">
			    </el-option>
			  </el-select>
			  <span style="display: inline-block;width: 10px;"></span>
			  <el-button type="primary" @click="preview" v-if="action.updatetable">预览</el-button>
			  <el-button type="primary" @click="standardSure(1)" v-if="action.updatetable">确认</el-button>
			  <el-button type="primary" @click="back">返回</el-button>
			</div>
			<div class="header-right">
				<el-input v-model="searchValue" placeholder="搜索"></el-input>
				<div class="hdBtnBox" style="display: flex; margin-left: 10px;">
					<el-button type="primary" icon="el-icon-search">搜索</el-button>
					<el-button type="primary" icon="el-icon-upload2" @click="derive" v-if="action.exporttable">导出</el-button>
					<el-button type="primary" icon="el-icon-upload2" @click="importexcel" v-if="action.importtable">导入</el-button>
					<el-button type="primary" @click="save" v-if="action.updatetable">保存</el-button>
					<el-button type="primary" @click="getUpdate" >获取更改</el-button>
				</div>
			</div>
		</div>
		<!-- 表格区域 -->
		<div class="table-container">
			<!-- 有编辑权限时 -->
			<el-table v-if="action.updatetable" ref="table" height="71vh" border :data="tableData" style="width: 100%" :cell-style="setColor">
				<!-- 最左侧区域 -->
				<el-table-column v-for="(col,index) in headersOne" :key="Math.random()" :prop="col.prop" :label="col.label" fixed>
					<template slot-scope="scope">
						<p style="width: 100%;height:100%;min-height:20px;" ref="updateP">{{scope.row[col.prop]}}</p>
						<!-- <div style="display: none;">
							<div style="display: flex;">
								<el-input size="mini" v-model="inputValue" @blur="sureMth(0,$event,scope.$index,col.prop,index)"></el-input>
								<el-button size="mini" @click="sureMth(1,$event,scope.$index,col.prop,index)">确定</el-button>
							</div>
						</div> -->
					</template>
				</el-table-column>
				<!-- 下拉选择列 -->
				<el-table-column prop="data" width="150" fixed="">

					<template slot="header" slot-scope="scope">
						<el-dropdown trigger="click">
							<span class="el-dropdown-link" style="color: #000000;font-size: 16px;font-weight: bold;">
								{{columnuName}}<i class="el-icon-arrow-down el-icon--right"></i>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item v-for="item in options">
									<p @click="changeDrop(item)">{{item}}</p>
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</template>
					<template slot-scope="scope">
						<p>{{scope.row.field2}}</p>
					</template>
				</el-table-column>
				<!-- 流水号 -->
				<!-- <el-table-column v-for="(col,index) in headersOne" :key="Math.random()+index" :prop="col.prop" label="流水号" fixed>
					<template slot-scope="scope">
						<p style="width: 100%;height:100%;min-height:20px;" ref="updateP">暂无</p>
					</template>
				</el-table-column> -->
				<!-- 右侧参数列 -->
				<el-table-column  v-for="(col,index) in headers" :key="Math.random()" :label="col.label" >
					<!-- 头部 -->
					<template slot="header" slot-scope="scope">
						<div>
							<p @click="updateHd($event,col.label,index)" style="min-height: 20px;" class="header-container"><span>{{col.label}}</span>
							<!-- <span v-if="index>0" class="el-icon-close closeBtn" @click="deleteCol(index,col.prop,col.key)"></span></p> -->
							<span v-if="index>=1"  class="el-icon-close closeBtn" @click.stop="deleteCol(index,col.prop,col.key)"></span></p>
						</div>
						<div style="display: none;">
							<div class="hdUpadte" style="display: flex;">
								<el-input size="mini" v-model="headValue" @blur="sureHead(0,$event,index)"></el-input>
								<el-button size="mini" icon="el-icon-check" circle></el-button>
							</div>
						</div>
					</template>
					<!--表内内容-->
					<template slot-scope="scope">
						<!-- 通过点击表格内内容实现显示输入框修改数据 -->
						<p style="width: 100%;height:100%;min-height:20px;" ref="updateP" @click="update($event,scope.row[col.prop],index,scope.row)">{{scope.row[col.prop]}}</p>
						<!-- <span>{{col.key}}</span> -->
						<div style="display: none;">
							<div style="display: flex;" class="tableC">
								<el-input size="mini" v-model="inputValue" @blur="sureMth(0,$event,scope.$index,col.prop,index,scope.row,col.key,scope.row[col.prop])"></el-input>
								<el-button size="mini" icon="el-icon-check" circle @click="sureMth(1,$event,scope.$index,col.prop,index,scope.row,col.key,scope.row[col.prop])"></el-button>
								<!-- <el-button ></el-button> -->
							</div>
						</div>
						<!-- <div v-show="{}"></div> -->
						<!-- <p>{{scope.column}}</p> -->
					</template>
				</el-table-column>
				<!-- 最右侧添加栏 -->
				<el-table-column fixed="right"  :width="widthHB" prop="date" :render-header="renderHeader"></el-table-column>
			</el-table>
			<!-- 没有编辑权限时 -->
			<el-table v-if="!action.updatetable" ref="table" height="71vh" border :data="tableData" style="width: 100%" :cell-style="setColor">
				<!-- 最左侧区域 -->
				<el-table-column v-for="(col,index) in headersOne" :key="Math.random()" :prop="col.prop" :label="col.label" fixed>
					<template slot-scope="scope">
						<p style="width: 100%;height:100%;min-height:20px;" ref="updateP">{{scope.row[col.prop]}}</p>
						<!-- <div style="display: none;">
							<div style="display: flex;">
								<el-input size="mini" v-model="inputValue" @blur="sureMth(0,$event,scope.$index,col.prop,index)"></el-input>
								<el-button size="mini" @click="sureMth(1,$event,scope.$index,col.prop,index)">确定</el-button>
							</div>
						</div> -->
					</template>
				</el-table-column>
				<!-- 下拉选择列 -->
				<el-table-column prop="data" width="150" fixed="">
					<template slot="header" slot-scope="scope">
							<span class="el-dropdown-link" style="color: #000000;font-size: 16px;font-weight: bold;">
								{{columnuName}}
							</span>
					</template>
					<template slot-scope="scope">
						<p>{{scope.row.field2}}</p>
					</template>
				</el-table-column>
				<!-- 流水号 -->
				<!-- <el-table-column v-for="(col,index) in headersOne" :key="Math.random()+index" :prop="col.prop" label="流水号" fixed>
					<template slot-scope="scope">
						<p style="width: 100%;height:100%;min-height:20px;" ref="updateP">暂无</p>
					</template>
				</el-table-column> -->
				<!-- 右侧参数列 -->
				<el-table-column  v-for="(col,index) in headers" :key="Math.random()" :label="col.label" >
					<!-- 头部 -->
					<template slot="header" slot-scope="scope">
						<div>
							<p style="min-height: 20px;" class="header-container"><span>{{col.label}}</span>
					</p>
						</div>

					</template>
					<!--表内内容-->
					<template slot-scope="scope">
						<!-- 通过点击表格内内容实现显示输入框修改数据 -->
						<p style="width: 100%;height:100%;min-height:20px;" ref="updateP">{{scope.row[col.prop]}}</p>
					</template>
				</el-table-column>
				<!-- 最右侧添加栏 -->

			</el-table>
		</div>

		<!-- <el-button raw-type="button" @click="addCol">
			添加一列
		</el-button> -->
		<!-- 预览弹出层 -->
		<el-dialog
		  title="预览"
		  :visible.sync="previewDia"
		  :close-on-click-modal="false"
		  width="30%">
		  <el-tree
			ref="treeOne"
		    :data="selectItem"
		    show-checkbox
		    node-key="id"
			default-expand-all
			:props="defaultProps">
		  </el-tree>
		  <div class="dia-btn" style="display: flex;justify-content: flex-end;">
			  <el-button type="primary" @click="standardSure(0)">确认</el-button>
		  </div>
		</el-dialog>
		<!-- 导入弹出框 -->
		<el-dialog
		  title="导入"
		  :visible.sync="importexcelDia"
		  :close-on-click-modal="false"
		  width="30%">
		  <el-upload
		    class="upload-demo"
		    drag
		    action :http-request="upload" :limit="1" :file-list="filesList"
		    multiple>
		    <i class="el-icon-upload"></i>
		    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
		    <div class="el-upload__tip" slot="tip">只能上传excel文件</div>
		  </el-upload>
		  <div class="dia-btn" style="display: flex;justify-content: flex-end;">
			  <el-button type="primary" @click="importMth">确认</el-button>
		  </div>
		</el-dialog>
	</div>
</template>
<script>
import { mapState } from 'vuex'
export default {
// 		props:{
//             tableGuid: String,
// 			tablename:String
//         },
  props: ['tableGuid', 'tablename'],
  data () {
    return {
      filesList: [],
      importexcelDia: false,
      tableData: [],
      newTableData: [],
      datas: {
        'tablename': '表名称（族类别）',
        'tabledata': []
      },
      headers: [],
      addBtn: true,
      addValue: '',
      yuansiLength: 0,
      inputValue: '',
      yuansiValue: '',
      widthHB: 50,
      options: ['水石BIM分类编码标准'],
      value: '水石BIM分类编码标准',
      headersOne: [],
      searchValue: '',
      headValue: '',
      editRow: {},
      addColList: {},
      deleteColList: [],
      yuansiHeaders: [],
      yuansiMax: 0,
      yuansiAdd: 1,
      yuansiHeadersLenght: 0,
      headerInfo: {},
      // tableName:'',
      tableNameList: [],
      selectValue: '',
      indexNum: 0, // 用来修改索引
      addList: [], // 用来保存新增的列的prop
      minWidth: 200,
      changeIndex: 5,
      lastValue: '',
      selectList: [],
      enterprise: '',
      standardList: [],
      addStandardList: [],
      oldStandardList: [],
      newStandard: '',
      oldStandard: '',
      previewDia: false,
      selectItem: [],
      defaultProps: {
				          children: 'parameterInfoList',
				          label: 'name'
				        },
      oldList: [],
      isUpdate: false, // 用于标识是否对表格进行了修改
      rowColor: [],
      columnColor: [],
      addColumnList: [],
      codingHeader: [],
      columnuName: ''
      // codingData:[]
      // 门
    }
  },
  mounted () {
    this.getTableList()
    this.getStandardList()
    // console.log(this.tablename)
    // this.getCoding()
  },
  computed: {
		  ...mapState('action', {
		    action: 'action'
		  })
  },
  methods: {
    // 获取构建编码
    getCoding () {
      this.$axios.get(`/api/formhandle/componentcoding?mappingTable=${this.tableGuid}`).then(res => {
        console.log(res)
        console.log(this.tableData)
        // for(var item in this.tableData)
        this.tableData.forEach(item => {
          // console.log(item)
          let obj = res.filter(item2 => {
            // console.log(item2)
            // console.log(item)
            return item2.elementID == item.field1
          })
          console.log(obj)
          // item.field2 = obj[0].componentCoding
          // item.field3 = obj[0].serialNumber
          if (obj.length) {
            item.field2 = obj[0].componentCoding
            item.field3 = obj[0].serialNumber
          } else {
            item.field2 = '暂无'
            item.field3 = '暂无'
          }
        })
        // for(var key in this.tableData){
        // 	console.log(key)
        // 	let obj = res.filter(item2=>{
        // 		return item2.elementID==this.tableData[key].field1
        // 	})
        // 	var item = this.tableData[key]
        // 	item.field2 = obj[0].componentCoding
        // 	item.field3 = obj[0].serialNumber
        // 	this.$set(this.tableData,key,item)
        // }
        this.dataCreate()
      })
    },
    preview () {
      if (this.selectItem.length == 0) {
        this.$message({
          showClose: true,
          message: '请先选择标准或该标准为空',
          type: 'warning'
        })
        return
      }
      this.previewDia = true
      console.log(this.selectItem)
      var keys = []
      for (var item of this.selectItem) {
        keys.push(item.id)
        for (var item2 of item.parameterInfoList) {
          keys.push(item2.id)
        }
      }
      this.$nextTick(function () {
        this.$refs.treeOne.setCheckedKeys(keys)
      })
    },
    // 更换表名
    // valueChange(value){
    // 	// if(value == '门'){
    // 	// 	this.enterprise = '-2000023'
    // 	// }else if(value == '家具||家具1'){
    // 	// 	this.enterprise = '-2000080'
    // 	// }else if(value == '结构柱'){
    // 	// 	this.enterprise = '-2001330'
    // 	// }else{
    // 	// 	this.enterprise = '-2001330'
    // 	// }
    // 	// this.tableName = value
    // 	// // console.log(this.tableName)
    // 	// this.getData()
    // },
    // 更换标准
    valueChange (value) {
      // console.log(this.standardList)
      // console.log(value)
      const newObj = this.standardList.filter(item => {
        return item.name == value
      })
      // console.log(newObj)
      // newStandard:'',
      // oldStandard:'',
      this.newStandard = value
      const arr = []
      for (var item of newObj[0].parameterList) {
        // for(var item2 of item.par)
        arr.push(...item.parameterInfoList)
      }
      const arr2 = this.standardList.filter(item => {
        return item.name == value
      })
      console.log(arr2)
      this.selectItem = arr2[0].parameterList
      console.log(this.selectItem)
      this.addStandardList = arr
      // console.log(arr)
    },
    back () {
      this.$parent.reback()
    },
    // 确定事件
    standardSure (type) {
      this.isUpdate = true
      // 如果是预览的需要赛选一边数据，将勾选的拿出来
      if (!type) {
        var list = this.$refs.treeOne.getCheckedNodes()
        var data = list.filter(item => {
          return !item.parameterInfoList
        })
        // console.log(data)

        // 上一次渲染的有数据的时候先删掉
        if (this.oldList.length > 0) {
          // console.log(this.oldStandardList)
          // const delData = this.oldStandardList
          // const delKeyData = []
          // const filtArr = []
          // for(let item of delData){
          // 	filtArr.push(item.name)
          // }
          // for(let item in this.headerInfo){
          // 	for(let item2 of filtArr){
          // 		if(this.headerInfo[item][0]== item2) {
          // 			delKeyData.push(item)
          // 		}
          // 	}
          // }
          console.log(this.oldList)
          console.log(this.headerInfo)
          console.log(this.headers)
          let delKeyData = []
          const filtArr = []
          for (let item of this.oldList) {
            filtArr.push(item.name)
          }
          for (let item in this.headerInfo) {
            for (let item2 of filtArr) {
              if (this.headerInfo[item][0] == item2) {
                delKeyData.push(item)
              }
            }
          }
          // 在eaderInfo中删除上一次的
          for (let item of delKeyData) {
            delete this.headerInfo[item]
          }
          // //在生成表头的数据中过滤掉上一次标准都的数据
          const headerList = this.headers.filter(item => {
            return delKeyData.every(item2 => {
              return item2 != item.prop
            })
          })
          this.headers = headerList
          console.log(this.headers)
        }
        for (let item of data) {
          let name = item.name
          let fie = 'field' + (Number(this.yuansiMax) + Number(this.yuansiAdd))
          this.headers.push({
            // 因为最前面有一列被单独拿走，所以要加2
            prop: fie,
            label: name
          })
          this.addList.push(fie)
          this.headerInfo[fie] = [name, "False;False;-1;False;3;'';-1"]
          this.yuansiAdd += 1
          this.indexNum += 1
        }
        this.oldList = data
      } else {
        const data = this.addStandardList
        // 如果两次选择的是一样的 直接return
        if (this.newStandard == this.oldStandard) {
          return
        }
        // 如果旧的是空，代表是第一次选择，直接渲染数据就行了，不用删除上一次的
        if (this.oldStandard == '') {
          for (let item of data) {
            let name = item.name
            let fie = 'field' + (Number(this.yuansiMax) + Number(this.yuansiAdd))
            this.headers.push({
              // 因为最前面有一列被单独拿走，所以要加2
              prop: fie,
              label: name
            })
            this.addList.push(fie)
            this.headerInfo[fie] = [name, "False;False;-1;False;3;'';-1"]
            this.yuansiAdd += 1
            this.indexNum += 1
          }
          // 深度克隆
          this.oldStandard = this.newStandard
          this.oldStandardList = JSON.parse(JSON.stringify(this.addStandardList))
        } else {
          const delData = this.oldStandardList
          const delKeyData = []
          const filtArr = []
          for (let item of delData) {
            filtArr.push(item.name)
          }
          for (let item in this.headerInfo) {
            for (let item2 of filtArr) {
              if (this.headerInfo[item][0] == item2) {
                delKeyData.push(item)
              }
            }
          }
          // 在eaderInfo中删除上一次的
          for (let item of delKeyData) {
            delete this.headerInfo[item]
          }
          // 在生成表头的数据中过滤掉上一次标准都的数据
          const headerList = this.headers.filter(item => {
            return delKeyData.every(item2 => {
              return item2 != item.prop
            })
          })
          this.headers = headerList
          console.log(this.headerInfo)
          console.log(this.headers)
          // this.$delete(this.headers, index)
          const data = this.addStandardList
          for (let item of data) {
            let name = item.name
            let fie = 'field' + (Number(this.yuansiMax) + Number(this.yuansiAdd))
            this.headers.push({
              // 因为最前面有一列被单独拿走，所以要加2
              prop: fie,
              label: name
            })
            this.addList.push(fie)
            // console.log(this.headerInfo)
            // console.log(this.headerInfo.field1)
            this.headerInfo[fie] = [name, "False;False;-1;False;3;'';-1"]
            // console.log(this.headerInfo)
            // console.log(this.headerInfo)
            this.yuansiAdd += 1
            this.indexNum += 1
          }
          // 深度克隆
          this.oldStandard = this.newStandard
          this.oldStandardList = JSON.parse(JSON.stringify(this.addStandardList))
        }

        // 当切换了选项时需要删除上一个选项的列
      }
      this.$nextTick(() => {
				      this.$refs.table.doLayout()

				     // table那里加一个ref="table"
      })
    },
    // 获取表的列表
    getTableList () {
      // var currSpaceId = 1
      // var currProjectId = 5
      // const token = localStorage.getItem("eleToken")
      // // console.log(token)
      // this.$axios.get(`/api/formhandle/gettablelist`,{headers:{
      // 	// Authorization:token,
      // 	'currSpaceId':currSpaceId,
      // 	'currProjectId':currProjectId
      // }}).then(res=>{
      // 	console.log(res)
      // 	for(var item of res){
      // 		this.tableNameList.push(item[0])
      // 	}
      // 设置默认的表名为获取到的表名列表的第一个
      // this.tableName = this.tableNameList[0]
      // this.selectValue = this.tableNameList[0]
      // this.enterprise = '-2000023'
      this.getData()
      // })
    },
    // 导出excel
    derive () {
      // console.log(this.tableData)
      // console.log(this.yuansiHeaders)
      console.log(this.tableGuid)
      console.log(this.tablename)
      // this.$axios.get(`/api/formhandle/exportexcel?mappingTable=${this.tableGuid}&tableName=${this.tablename}`).then(res=>{
      // 	console.log(res)
      // })
      // return
      this.$axios.get(`/api/formhandle/exportexcel?mappingTable=${this.tableGuid}&tableName=${this.tablename}`, { responseType: 'arraybuffer' }).then(res => {
        // console.log(res)
        let type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
        const href = URL.createObjectURL(new Blob([res], { type }))
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = href // 指定下载链接
        a.download = this.tablename// 指定下载文件名
        a.click() // 触发下载
        URL.revokeObjectURL(a.href) // 释放URL对象

        this.$message({
          showClose: true,
          message: '下载完成',
          type: 'success'
        })
      })
    },
    // 获取最新更改
    async getUpdate (type) {
      let fixData = []
      var addNewCol = []
      await this.$axios.get(`/api/formhandle/gettableupdatedata?mappingTable=${this.tableGuid}`).then(res => {
        console.log(res)
        if (res == '当前表暂无更改记录') {
          this.$message({
            showClose: true,
            message: res,
            type: 'warning'
          })
        } else {
          this.$message({
            showClose: true,
            message: '已成功获取当前表更改记录',
            type: 'success'
          })
          fixData = res.handleData
          // console.log(fixData)
          addNewCol = res.importTableMeta.addTableHeaderName
          this.addColumnList = []
          console.log(this.tableData)
          let rowIndex = 0
          for (var key in this.tableData) {
            for (var fixValue of fixData) {
              // 判断是否有这一行的数据
              if (this.tableData[key].field1 == fixValue.elementId) {
                // console.log(this.tableData[key])
                // 判断这一行书否是否被更新
                console.log(fixValue)
                if (fixValue.updataOldCol) {
                  // console.log(fixValue.updataOldCol)
                  // 这里进行修改
                  for (var arrupdate of fixValue.updataOldCol) {
                    // console.log(this.tableData[key])
                    let data = this.tableData[key]
                    let flag = 1
                    var fie = ''
                    for (var field in this.tableData[key]) {
                      if (flag == arrupdate[0]) {
                        this.tableData[key][field] = arrupdate[1]
                        this.rowColor.push(rowIndex)
                        this.columnColor.push(flag - 1)
                      }
                      flag++
                    }
                    // [...new Set(arr)]
                  }
                }
              }
            }
            rowIndex++
          }
          // this.rowColor = [...new Set(this.rowColor)]
          // this.columnColor = [...new Set(this.columnColor)]
          console.log(this.rowColor)
          console.log(this.columnColor)
          // 新增只用拿一次。所以不用循环
          // 判断是否有新增，如果有就先改变头部再改变数据
          var ind = 0

          console.log(addNewCol)
          for (let item of addNewCol) {
            console.log(item)

            if (type == 1) {
              var fie = 'field' + (Number(this.yuansiMax) + Number(this.yuansiAdd))
              this.headers.push({
                // 因为最前面有一列被单独拿走，所以要加2
                prop: fie,
                label: item
              })
              this.addList.push(fie)
              this.column
              // console.log(this.headerInfo)
              // console.log(this.headerInfo.field1)
              // this.headerInfo[fie]=[this.addValue,"False;False;-1;False;3;'';-1"]
              // console.log(this.headerInfo)
              // console.log(this.headerInfo)
              this.yuansiAdd += 1
              this.indexNum += 1
              for (let idItem of fixData) {
                // console.log(idItem)
                for (let key in this.tableData) {
                  // console.log(key)
                  if (this.tableData[key].field1 == idItem.elementId) {
                    this.tableData[key][fie] = idItem.addNewCol[ind][1]
                  }
                }
              }
              ind++
              // this.isUpdate = true
              // this.indexnnUM
              // this.addColList.tableHeaderName.push(this.addValue)
              // this.addBtn = true
              // this.addValue = ''
              // this.widthHB = 50
              console.log(this.tableData)
            } else {
              console.log(this.headers)
              console.log(item)
              var index = this.headers.findIndex(item2 => {
                return item2.label == item
              })
              console.log(index)
              this.addColumnList.push(index + 2)

              // addColumnList
            }
          }

          // 新增数据
          this.importexcelDia = false
        }
      })
    },
    // 导入
    importexcel () {
      console.log(this.tableGuid)
      this.importexcelDia = true
      this.rowColor = []
      this.columnColor = []
    },
    upload (file) {
      console.log(file)

      this.filesList.push({
        name: file.file.name,
        singlefile: file.file,
        url: URL.createObjectURL(file.file)
      })
      // if(this.filesList.length>=1){
      // 	let upload_div = document.getElementsByClassName('avatar-uploader')[0].childNodes[1];
      // 	upload_div.style.cssText = "display: none;";
      // }
    },
    // 确认导入
    async importMth () {
      // const
      let formData = new FormData()
      formData.append('tableName', this.tablename)
      formData.append('mappingTable', this.tableGuid)
      // console.log
      formData.append('formFile', this.filesList[0].singlefile)
      // console.log(formData)
      // return
      await this.$axios.post('/api/formhandle/importexcel', formData).then(res => {
        console.log(res)
        if (res == '上传成功') {
          this.$message({
            showClose: true,
            message: '上传成功',
            type: 'success'
          })
        } else {
          this.$message({
            showClose: true,
            message: res.Message,
            type: 'error'
          })
          // return
        }
        // 改变成功之后清空上传的文件列表
        this.filesList = []
      })
      // return
      // this.filesList = []
      // 获取更改记录并变色
      this.getUpdate('1')
    },
    // 生成构建编码中的数据
    dataCreate () {
      var data = this.tableData
      console.log(data)
      for (let items of data) {
        let item = JSON.parse(JSON.stringify(items))
        console.log(item.field3)
        let aid = item.field1
        // let fie2 = item.field2
        // console.log(item)
        // console.log(aid)
        // console.log(fie2)
        // console.log(item)
        if (this.editRow[aid]) {
          // 如果存在更新数据
          if (this.editRow[aid].updataOldCol) {
            // console.log(item.field3)
            this.editRow[aid].updataOldCol[2] = {
              keyNumber: '2',
              value: item.field2
            }
            this.editRow[aid].updataOldCol[3] = {
              keyNumber: '3',
              value: item.field3
            }
          } else {
            // console.log(item.field3)
            this.editRow[aid].updataOldCol[2] = {
              keyNumber: '2',
              value: item.field2
            }
            this.editRow[aid].updataOldCol[3] = {
              keyNumber: '3',
              value: item.field3
            }
          }
          // if(this.editRow[aid].updataOld)
        } else {
          // 如果该行数据不存在
          // console.log(11111111)
          // console.log(item.field2)
          this.editRow[aid] = {
            elementId: aid,
            updataOldCol: {
              2: {
                keyNumber: '2',
                value: item.field2
              },
              3: {
                keyNumber: '3',
                value: item.field3
              }
            }
          }
        }
      }
      this.isUpdate = true
      // console.log(this.editRow)
    },
    // 构件分类编码改变
    changeDrop (item) {
      this.getCoding()
    },
    // 头部修改，这里只是点击之后触发输入框
    updateHd (e, hvalue, index) {
      // 现阶段只能新添加的可以修改
      if (index > this.yuansiLength) {
        if (e.path[0].tagName == 'SPAN') {
          e.path[3].children[1].style.display = 'block'
          e.path[3].children[0].style.display = 'none'
          e.path[3].children[1].children[0].children[0].children[0].focus()
          this.headValue = hvalue
        } else {
          e.path[2].children[1].style.display = 'block'
          e.path[2].children[0].style.display = 'none'
          e.path[2].children[1].children[0].children[0].children[0].focus()
          this.headValue = hvalue
        }
      }
    },
    // 获取标准列表
    getStandardList () {
      this.$axios.get('/api/standard/getstandardlist?type=2').then(res => {
        console.log(res)
        // const arr = []
        // for(var item of res){
        // 	for(var item1 of item.parameterList){
        // 		arr.push(...item1.parameterInfoList)
        // 	}
        // }
        // // console.log(arr)
        this.standardList = res
      })
    },
    // 点击头部得确定或者输入框失去焦点之后触发数据更新
    sureHead (type, e, index) {
      if (!type) {
        e.path[4].children[0].style.display = 'block'
        e.path[4].children[1].style.display = 'none'
        var obj = this.headers[index]
        obj.label = this.headValue
        this.$set(this.headers, index, obj)
        this.isUpdate = true
      }
    },
    // 生成数据
    dataMth () {
      const headerArr = []
      for (var item of this.headers) {
        headerArr.push(item.label)
      }
      console.log(this.editRow)
      // console.log(headerArr)
      // 不能直接删掉最初的数据
      const addHeader = headerArr.splice(this.yuansiHeadersLenght)
      // console.log(addTableMeta)
      // 得到所有表内行的修改
      const handleArr = Object.values(this.editRow)
      // var newHandle = []
      for (var index in handleArr) {
        if (handleArr[index].updataOldCol) {
          // console.log(handleArr[index])
          handleArr[index].updataOldCol = Object.values(handleArr[index].updataOldCol)
          var newArr = []
          for (var item of handleArr[index].updataOldCol) {
            // console.log(item)
            newArr.push(Object.values(item))
          }
          handleArr[index].updataOldCol = newArr
        } else {
          handleArr[index].updataOldCol = []
        }
        // 判断存不存在新增
        if (handleArr[index].addNewCol) {
          // console.log(handleArr[index])
          handleArr[index].addNewCol = Object.values(handleArr[index].addNewCol)
          var newArr = []
          for (var item of handleArr[index].addNewCol) {
            // console.log(item)
            newArr.push(Object.values(item))
          }
          handleArr[index].addNewCol = newArr
        } else {
          handleArr[index].addNewCol = []
        }
      }
      // 得到headerInfo
      // console.log(Object.values(this.headerInfo))
      var hInfo = Object.values(this.headerInfo)
      // for(var i in hInfo){
      // 	hInfo[i] = Object
      // }
      // 得到删除的列
      // 没有标识先置为空
      // console.log(hInfo)
      var flagRevit = false
      console.log(hInfo)
      for (var i in hInfo) {
        // console.log(hInfo[i])
        // console.log(hInfo[i][1].split(";").length)
        console.log(typeof hInfo[i])
        if (typeof hInfo[i] !== 'object' || hInfo[i][1].split(';').length < 6) {
          // if(hInfo[i][1].split(";").length < 6 ){
          flagRevit = true
          hInfo[i] = ['族名称', "False;False;-1;False;3;'';-1"]
          // }
        }
      }
      if (flagRevit) {
        this.$message({
          showClose: true,
          message: '族名称 revit参数设置有误',
          type: 'warning'
        })
      }
      // return
      const delOldCol = []
      const importTableMeta = {
        addTableHeaderName: addHeader,
        tableHeaderInfo: hInfo
      }
      if (handleArr.length == 0) {
        handleArr.push({})
      }
      // 生成最终数据
      const datas = {
        importTableMeta: importTableMeta,
        handleData: handleArr,
        delOldCol: this.deleteColList
      }
      // console.log(JSON.stringify(datas))
      return datas
    },
    async save () {
      if (!this.isUpdate) {
        this.$message({
          showClose: true,
          message: '数据未发生修改',
          type: 'warning'
        })
        return
      }
      const data = await this.dataMth()
      // console.log(data)
      console.log(JSON.stringify(data))
      // console.log(this.tableName)
      // return
      await this.$axios.post(`/api/formhandle/updateformdata?tablename=${this.tablename}&mappingTable=${this.tableGuid}`, data).then(res => {
        // console.log(res)
        if (res == '更新成功') {
          // 更新成功之后初始化相关数据才能不影响下次修改
          this.getData()
          this.editRow = {}
          this.deleteColList = []
          this.addList = []
          this.$message({
            showClose: true,
            message: '修改成功',
            type: 'success'
          })
          this.isUpdate = false
        } else {
          this.$message({
            showClose: true,
            message: '发生错误',
            type: 'error'
          })
        }
      })
    },
    // 获取构建编码

    // 获取初始数据与数据处理
    getData () {
      // console.log(this.tableName)
      this.headers = []
      this.headersOne = []
      this.tableData = []
      this.newTableData = []
      this.headerInfo = {}
      this.yuansiMax = 0
      this.yuansiHeaders = []
      console.log(this.tableGuid)
      this.$axios.get(`/api/formhandle/getformdata?mappingTable=${this.tableGuid}`).then(res => {
        var newObj = JSON.parse(res)
        console.log(newObj)
        this.columnuName = newObj[0].field2
        // 表为空就不往下继续了
        if (!newObj.length) {
          return
        }
        // 将头部分成两块，中间插入下拉选择
        var i = 0
        for (var key in newObj[0]) {
          // console.log(key)
          this.headers.push({
            prop: key,
            label: newObj[0][key],
            key: i++
          })
          this.yuansiHeaders.push(key)
        }
        // 获取filed后面最大的值，在添加列的时候用于生成新增列的filedx，该数据仅用于前端，不会发送给后端服务器
        this.yuansiMax = this.yuansiHeaders[this.yuansiHeaders.length - 1].substring(5)
        // 获取左侧的表格列，现在获取的是第一列
        var headersOne = this.headers.splice(0, 1)
        this.codingHeader = this.headers.splice(0, 1)
        // this.codingData =
        this.headersOne = headersOne
        this.tableData = newObj.slice(2)
        this.newTableData = newObj.slice(2)
        console.log(this.tableData)
        this.headerInfo = newObj[1]
        console.log(this.headerInfo)
        // for(var item of this.tableData){
        // 	this.codingData.push({coding:item.field2})
        // }
        // console.log(this.codingData)

        // console.log(this.headers)
        // this.yuansiHeaders =
        this.yuansiLength = this.headers.length - 1
        this.yuansiHeadersLenght = this.headers.length
        this.$nextTick(() => {
					      this.$refs.table.doLayout()

					     // table那里加一个ref="table"
        })
      })
    },
    statusStyle ({ row, column, rowIndex, columnIndex }) {
      // console.log(rowIndex)
			      // if (this.changeIndex == 5) {
			        return 'color:#ED3F14'
			      // }
			    },
    update (e, value, index, row) {
      // console.log(index)
      // if(index <= 3){
      // 	return
      // }
      this.minWidth = 400
      // this.fixCol = 200
      // console.log(value)
      // this.changeIndex = index+2
      // console.log(row)
      if (index >= 1) {
        e.path[1].children[0].style.display = 'none'
        e.path[1].children[1].style.display = 'block'
        this.inputValue = value
        this.yuansiValue = value
        e.path[1].children[1].children[0].children[0].children[0].focus()
      } else {

      }
    },
    // 该方法用户控制是否修改，现在暂时没有用到
    // inputMth(value) {
    // 	if (value != this.yuansiValue) {
    // 		return true
    // 	} else {
    // 		return false
    // 	}

    // },
    // 确定修改之后对数据和样式进行修改
    sureMth (type, e, index, key, indexItem, id, k, value) {
      var keyNumber = indexItem + 2
      keyNumber = keyNumber.toString()
      // 样式改变
      if (type) {
        if (e.path[0].tagName == 'SPAN') {
          e.path[4].children[0].style.display = 'block'
          e.path[4].children[1].style.display = 'none'
        } else {
          e.path[3].children[0].style.display = 'block'
          e.path[3].children[1].style.display = 'none'
        }
        // 数据改变
      } else {
        if (e.path[0].tagName == 'SPAN') {
          console.log(e.path[5])
          e.path[5].children[0].style.display = 'block'
          e.path[5].children[1].style.display = 'none'
        } else {
          // console.log(e.path[4])
          e.path[4].children[0].style.display = 'block'
          e.path[4].children[1].style.display = 'none'
        }
        // 数据改变
      }
      // 如果值没有改变就直接终止
      if (value == this.inputValue) {
        console.log('终止')
        return
      }
      var newArr = this.tableData
      var obj = newArr[index]
      // 创建或者修改已经生成的数据
      // 判断数据数据是否发生改变
      var aid = id.field1
      // console.log(keyNumber)
      // console.log(this.yuansiHeadersLenght)

      if (obj[key] != this.inputValue && keyNumber <= this.yuansiHeadersLenght + 1) {
        // console.log('修改')
        // 此时发生了修改
        // 如果该行数据已经生成就去操作这个数据
        if (this.editRow[aid]) {
          if (this.editRow[aid].updataOldCol) {
            this.editRow[aid].updataOldCol[keyNumber] = {
              keyNumber: (k + 1).toString(),
              value: this.inputValue
            }
          } else {
            this.editRow[aid].updataOldCol = {
              [keyNumber]: {
                keyNumber: (k + 1).toString(),
                value: this.inputValue
              }
            }
          }
          // if(this.editRow[aid].updataOld)
        } else {
          // 如果该行数据不存在
          this.editRow[aid] = {
            elementId: aid,
            updataOldCol: {
              [keyNumber]: {
                keyNumber: (k + 1).toString(),
                value: this.inputValue
              }
            }
          }
        }
        // 如果是新增的就添加到新增里面
      } else {
        const len = this.yuansiHeaders.length
        var identifier = 0
        for (var i in this.addList) {
          if (key == this.addList[i]) {
            identifier = i - 0 + 1
          }
        }
        // 新增列的时候索引需要判断是新增钟的第几个，用这个第几个再加上原始的数据的长度
        // 新增和修改都分为这一行的数据已经存在和不存在，已经存在的时候要看是存在新增还是修改，没有新增就添加，有新增就修改。修改数据功能实现相同
        if (this.editRow[aid]) {
          if (this.editRow[aid].addNewCol) {
            this.editRow[aid].addNewCol[keyNumber] = {
              keyNumber: (len - 0 + identifier).toString(),
              value: this.inputValue
            }
          } else {
            this.editRow[aid].addNewCol = {
              [keyNumber]: {
                keyNumber: (len - 0 + identifier).toString(),
                value: this.inputValue
              }
            }
          }
        } else {
          // 如果该行数据不存在
          this.editRow[aid] = {
            elementId: aid,
            addNewCol: {
              [keyNumber]: {
                keyNumber: (len - 0 + identifier).toString(),
                value: this.inputValue
              }
            }
          }
        }
      }

      // console.log(this.editRow)

      // 数据改变放最后

      obj[key] = this.inputValue
      this.$set(this.tableData, index, obj)
      if (indexItem < this.yuansiLength + 1 && indexItem != this.headers.length) {
        if (this.inputValue != this.yuansiValue) {
          // console.log(e)
          if (e.path[0].tagName == 'SPAN') {
            e.path[6].style.backgroundColor = '#e6eff7'
          } else {
            e.path[5].style.backgroundColor = '#e6eff7'
          }
        }
      }
      this.isUpdate = true
    },
    // 删除某一列
    deleteCol (index, prop, key) {
      console.log(this.editRow)

      this.$confirm('此操作将删除该列数据，是否继续?', '提示', {
				          confirmButtonText: '确定',
				          cancelButtonText: '取消',
				          type: 'warning'
				        }).then(() => {
        this.addColumnList = []
        const plan = index + 2
        // 如果现在的那一列的索引小于yuansiHeadersLength(原始头部长度，右边部分),那么就代表删除的是原始的数据，不是新增的数据，否则删除的就是新增的数据
        if (plan <= this.yuansiHeadersLenght + 1) {
          console.log('原始')
          // 当删除的是原始的数据时，需要查看这一列是否有修改记录，如果有修改记录就删掉
          for (let k in this.editRow) {
            // 需要把新增中的对应的删除
            // delete item.addNewCol[plan]
            // console.log(this.editRow[k].updataOldCol)
            if (this.editRow[k].updataOldCol && this.editRow[k].updataOldCol[plan]) {
              // console.log(111111)
              delete this.editRow[k].updataOldCol[plan]
            }

            // let keyArr = Object.getOwnPropertyNames(this.editRow[k].addNewCol)
            // console.log(keyArr)
            // var num = 0
            // for(let index of keyArr){
            // 	console.log(index)
            // 	if(index > plan){
            // 		let lsObj = this.editRow[k].addNewCol[index]
            // 		lsObj.keyNumber = plan-0+num
            // 		delete this.editRow[k].addNewCol[index]
            // 		this.editRow[k].addNewCol[plan+num] = lsObj
            // 		num++
            // 	}
            // }
          }
        } else {
          // 当删除的是新增的数据时
          console.log('新增')
          for (let k in this.editRow) {
            // console.log(k)
            // 需要把新增中的对应的删除
            // delete item.addNewCol[plan]
            // console.log(item)
            delete this.editRow[k].addNewCol[plan]
            let keyArr = Object.getOwnPropertyNames(this.editRow[k].addNewCol)
            // console.log(keyArr)
            var num = 0
            for (let index of keyArr) {
              console.log(index)
              if (index > plan) {
                let lsObj = this.editRow[k].addNewCol[index]
                lsObj.keyNumber = (plan - 0 + num).toString()
                delete this.editRow[k].addNewCol[index]
                this.editRow[k].addNewCol[plan + num] = lsObj
                num++
              }
            }
            // 将后面新增的列的索引应该减1
            // 需要把修改中对应的删除
          }
        }
        console.log(this.editRow)

        // 改变头部的数据
        if (key) {
          this.yuansiHeadersLenght -= 1
        }
        // console.log(index)
        // console.log(key)
        // console.log(prop)
        // 同步信息钟删掉这一列
        delete this.headerInfo[prop]
        // console.log(this.headers[index])
        // console.log(this.yuansiLength)
        // 如果是原始的（服务器返回的数据）需要将返回的数据新增到删除列表钟
        if (index <= this.yuansiLength) {
          this.deleteColList.push((key + 1).toString())
        }
        var keyValue = this.headers[index].prop.substring(5)
        // 删除对应列
        this.$delete(this.headers, index)
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.isUpdate = true
				        }).catch(() => {
				          this.$message({
				            type: 'info',
				            message: '删除失败'
				          })
				        })
    },
    addCol () {
      // console.log(1111111)
      // console.log(this.headers)
      // 判断该列是否已经存在，存在就不能新增
      const a = this.headers.every(item => {
        return item.label != this.addValue
      })
      if (!a) {
        this.$message({
          showClose: true,
          message: '无法添加重复列',
          type: 'warning'
        })
        this.addBtn = true
        this.addValue = ''
        this.widthHB = 50
        this.$nextTick(() => {
					      this.$refs.table.doLayout()
        })
        return
      }
      // console.log(a)
      if (this.addValue) {
        var fie = 'field' + (Number(this.yuansiMax) + Number(this.yuansiAdd))
        this.headers.push({
          // 因为最前面有一列被单独拿走，所以要加2
          prop: fie,
          label: this.addValue
        })
        this.addList.push(fie)
        console.log(this.headerInfo)
        // console.log(this.headerInfo.field1)
        this.headerInfo[fie] = [this.addValue, "False;False;-1;False;3;'';-1"]
        console.log(this.headerInfo)
        // console.log(this.headerInfo)
        this.yuansiAdd += 1
        this.indexNum += 1
        this.isUpdate = true
        // this.indexnnUM
        // this.addColList.tableHeaderName.push(this.addValue)
      } else {
        this.$message({
          showClose: true,
          message: '无法添加空白列',
          type: 'warning'
        })
      }
      this.addBtn = true
      this.addValue = ''
      this.widthHB = 50
      this.$nextTick(() => {
				      this.$refs.table.doLayout()
      })
    },
    // noAddCol(){
    // 	console.log(11)
    // 	const that = this
    // 	// 失去焦点事件会和按钮事件冲突，用定时器来进行延时操作
    // 	setTimeout(function(){
    // 			that.addBtn = true
    // 			that.addValue = ''
    // 			that.widthHB = 50
    // 			that.$nextTick(()=>{
    // 			      that.$refs.table.doLayout()

    // 			     // table那里加一个ref="table"

    // 			})
    // 	},222)
    // },
    blurMth () {
      this.addBtn = true
      this.addValue = ''
      this.widthHB = 50
    },
    addColBtn () {
      this.addBtn = false
      this.widthHB = 200
      this.$nextTick(() => {
				      this.$refs.table.doLayout()

				     // table那里加一个ref="table"
      })
    },
    fixColorTable (cIndex, rIndex) {
      // 判断这一行、这一列是否存在
      const a = this.columnColor.indexOf(cIndex) != -1
      const b = this.rowColor.indexOf(rIndex) != -1
      // 判断对应关系
      var arr = []
      for (var ind in this.rowColor) {
        arr.push([this.rowColor[ind], this.columnColor[ind]])
      }
      let c = false
      for (var item of arr) {
        if (item[0] == rIndex && item[1] == cIndex) {
          c = true
        }
      }
      // console.log(arr)
      return a && b && c
    },
    setColor ({
      row,
      column,
      rowIndex,
      columnIndex
    }) {
      // console.log(columnIndex)
      if (columnIndex > this.yuansiLength + 3 && columnIndex != this.headers.length + 3) {
        return 'background:#f6faff;'
      }
      if (columnIndex == 0 || columnIndex == 1 || columnIndex == 2) {
        return 'background:#ececec;'
      }
      if (this.addColumnList.indexOf(columnIndex) != -1) {
        return 'background:#f6faff;'
      }
      if (this.fixColorTable(columnIndex, rowIndex)) {
        return 'background:#e6eff7;'
      }
    },
    renderHeader (h) {
      return (<div>
        <el-button style="color:#fff;background:#8b8b8b;" icon="el-icon-plus" size="mini" v-show={this.addBtn} on-click={this.addColBtn} circle></el-button>
        <div v-show={!this.addBtn} style="display:flex">
          <el-input size="mini" ref="addInputNode" on-blur={this.addCol} v-model={this.addValue}></el-input><el-button size="mini" icon="el-icon-check" circle on-click={this.addCol}></el-button>
        </div>
      </div>)
    }
  }
}
</script>
<style>
	.el-dialog__header{
		text-align: left;
	}
	.el-input--mini .el-input__inner {
		height: 23px;
		line-height: 23px;
		padding: 0;
	}

	.el-button--mini,
	.el-button--mini.is-round {
		padding: 1px 8px;
	}

	//修改table边框颜色
	.el-table--border:after,
	.el-table--group:after,
	.el-table:before {
		background-color: #e3e3e3;
	}

	.el-table--border,
	.el-table--group {
		border-color: #e3e3e3;
	}

	.el-table td,
	.el-table th.is-leaf {
		border-bottom: 1px solid #e3e3e3;
	}

	.el-table--border th,
	.el-table--border th.gutter:last-of-type {
		border-bottom: 1px solid #e3e3e3;
	}

	.el-table--border td,
	.el-table--border th {
		border-right: 1px solid #e3e3e3;
	}

	.el-icon-arrow-down:before {
		content: "\e790";
	}

	/* 对所有小图标进行了更改 */
	.el-button--mini.is-circle {
		margin-left: 5px;
		font-size: 16px;
		padding: 2px;
		border-color: #78c7e6;
		color: #159dd2;
		background-color: #ebf6fb;
		font-weight: bold;
	}

	.header-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		/* background-color: #aaffff; */
	}

	.closeBtn {
		display: none;
		cursor: pointer;
		font-size: 16px;
		color: #ac0000;
		/* transition: .5s; */
	}

	.header-container:hover .closeBtn {
		/* background-color: #000000; */
		display: inline;
		font-size: 16px;
		color: #ac0000;
	}

	.header-box {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 69px;
		/* line-height: 70px; */
		box-shadow: 0px 2px 15px 0px rgba(187, 187, 187, 0.5);
		border-radius: 8px;
		margin-bottom: 10px;
		background-color: #FFFFFF;
	}

	.header-left {
		display: flex;
		align-items: center;
		font-weight: bold;
		margin-left: 30px;
	}
	.header-left .el-button--primary{
		background: #0094CE;
		border: none;
	}

	.header-right {
		display: flex;
		height: 40px;
		margin-right: 30px;
	}

	.table-box {
		padding: 15px 25px;
		height: calc(100vh - 100px);
		box-sizing: border-box;
		background-color: #e7e7e7;
	}

	.table-container {
		height: calc(100vh - 250px);
		overflow-y: auto;
		box-shadow: 0px 2px 15px 0px rgba(187, 187, 187, 0.5);
		border-radius: 8px;
		background-color: #FFFFFF;
		padding: 20px;
	}

	/* .el-dropdown{
		display: block;
		width: 100%;
	}  */
	.hdBtnBox .el-button--primary:nth-child(1) {
		background: #475065;
		border: none;
	}

	.hdBtnBox .el-button--primary:nth-child(2) {
		background: #0094CE;
		border: none;
	}

	.hdBtnBox .el-button--primary:nth-child(3) {
		background: #0094CE;
		border: none;
	}
	.hdBtnBox .el-button--primary{
		background: #0094CE;
		border: none;
	}
</style>
